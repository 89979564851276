import { LazyLoadImage } from "react-lazy-load-image-component";

import  mocupExam  from "./../assets/images/mockup-exam.svg";
import  mocupQueue  from "./../assets/images/mockup-queue.svg";
import  background  from "./../assets/images/background.webp";

const Intro = () => {
    return (
        <div className="w-full h-fit flex justify-center overflow-hidden pb-4">
            <div className="max-w-screen-2xl pt-8 md:pt-[4.5rem] pb-12 md:pb-24 relative">
                <LazyLoadImage src={background} placeholder={<div className="block w-full h-full bg-white"></div>} width="1601" height="900" className="w-full absolute z-[-1] top-0 left-0 bottom-0 right-0 object-center" alt="MockUp Exam"/>
                <div className="m-auto w-full max-w-7xl px-5 sm:px-6 md:px-8 h-full flex items-center" >
                    <div className="grid grid-cols-12 gap-x-6 content-center" >
                        <div className="pt-4 lg:col-span-6 col-span-12 flex flex-col lg:justify-start lg:items-start items-center" >
                            <div className="flex flex-row gap-4 text-base font-semibold text-system-black">
                                <span className="border border-system-black px-2 rounded-full">#AI</span>
                                <span className="border border-system-black px-2 rounded-full">#образование</span>
                                <span className="border border-system-black px-2 rounded-full">#инновации</span>
                            </div>

                            <h1 className="mt-4 md:text-5xl text-2xl font-extrabold	text-system-black leading-[110%] lg:text-left text-center">
                            Новый Шаг в <br/><span className="text-system-blue">Оценке Образования</span>
                            </h1>

                            <p className="md:mt-8 mt-6 flex flex-row gap-4 md:text-2xl text-lg font-semibold text-system-black leading-[150%] lg:text-left text-center">
                                Оценивание навыков учащихся с полной автоматизированной проверкой знаний на основе ИИ.
                            </p>

                            <div className="mt-8 w-full flex lg:justify-start justify-center">
                                <a href="https://beta.edu-ai.by/exams" className="md:w-fit w-full" target="_blank" rel="noopener noreferrer" aria-label="Try free Education AI demo" >
                                    <div className="flex items-center justify-center md:w-fit w-full px-6 text-center cursor-pointer bg-system-blue text-white rounded h-11 text-base font-semibold">
                                        Начать демо
                                    </div>
                                </a>
                            </div>

                        </div>
                        <div className="md:pt-0 col-span-12 md:col-span-6 relative lg:block hidden">
                            <LazyLoadImage src={ mocupExam } placeholderSrc={ <div></div> } width="900" height="800" className="relative max-w-[150%] lg:max-w-[125%] xl:max-w-[130%]" alt="mock-up-exam" aria-label="Exam process"/>
                            <LazyLoadImage src={ mocupQueue } placeholderSrc={ <div></div> } width="317" height="337" className="absolute bottom-[-6em] left-[-6em] xl:block hidden" alt="mock-up-queue" aria-label="Queue"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
  
  export default Intro;
  

