import { ReactComponent as AppLogo } from './../assets/images/logo.svg';

const Header = () => {
    return (
      <header className="bg-white group sticky top-0 z-10 flex justify-center drop-shadow">
        <nav className="container h-14 md:h-[4.375rem] flex items-center sm:justify-between justify-center p-6 lg:px-8">
            <div>
                <a href="/" aria-label="Education AI">
                    <span className="sr-only">Education AI</span>
                    <AppLogo />
                </a>
            </div>
            <div className="sm:block hidden">
                <a href="https://beta.edu-ai.by/exams" target="_blank" rel="noopener noreferrer" aria-label="Try free Education AI demo" >
                    <div className="flex items-center w-fit px-6 text-center cursor-pointer bg-system-blue text-white rounded h-11 text-base font-semibold">
                        Начать демо
                    </div>
                </a>
            </div>
        </nav>
      </header>
    );
  }
  
  export default Header;
  