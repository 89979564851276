import React from "react";

import Header from "./components/Header";
import Intro from "./components/Intro";
const LazyBenefits =  React.lazy(() => import("./components/Benefits"));
const LazyEasy     =  React.lazy(() => import("./components/Easy"));
const LazyProcess  =  React.lazy(() => import("./components/Process"));
const LazyContact  =  React.lazy(() => import("./components/Contact"));
const LazyFooter   =  React.lazy(() => import("./components/Footer"));


const App = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="grow flex flex-col w-full">
        <div className="flex flex-col items-stretch">
          <main className="w-full grow flex">
            <Intro />
          </main>

          <LazyBenefits />
          <LazyEasy />
          <LazyProcess />
          <LazyContact />
          <LazyFooter />
        </div>
      </div>
    </div>
  );
}

export default App;
